@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
* {
  
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}



.btn-style {
  padding: 8px 20px;

  border: white;
  cursor: pointer;
}

.btn--primary {
  background-color: rgb(212, 212, 212);
  color: #242424;
  border: 1px solid  #fff;

}


.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #fff;
  transition: all 0.3s ease-out;

}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
}



.navbar-whole {
  background: #41b3a3;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1600px;
}

.navbar-logo:hover{
  color: #fff;
  text-decoration: none;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
  margin-top: 15px;
}

.nav-item {
  height: 80px;
}

.nav-links {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 110%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  margin-top: -5px;
  transition: all 0.2s ease-out;
  color:white;
  text-decoration: none;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

#btn-sign-up{
  background: #fff;
}


.navbar-logo-img{
  padding-left: 10px;
  height:35px;
}




video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  background:  linear-gradient(
    rgba(110, 108, 108, 0.4), 
    rgba(0, 0, 0, 0.9)
  ), url('/images/bgs/bg1.jpg')  center center/cover;
  height: 120vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}


.welcome-text{

  font-family: 'Bebas Neue',cursive;
  padding: 30px;

}


.hero-container > h1 {
  color: #fff;
  font-size: 140px;
  margin-top: 20px;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  padding-left: 200px;
}

.hero-container > p {
  margin-top: -30px;
  padding-left: 230px;
  margin-bottom: 50px;  
  color: #fff;
  font-size: 32px;
  font-family: 'Montserrat','Sans Serif';
 text-align: left;
}

.hero-btns {
  margin-top: 32px;
  padding-left: 230px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }

  .hero-container > h1 {
    padding-left: 20px;
  }
  .hero-container > p {
    padding-left: 20px;
  }
  .hero-btns{
    padding-left: 20px;
  }
}


.intro-box{
  padding: 4rem;
  background: #25283D;
}

.intro-container{
  display:flex;
}

.intro-wrapper{
  align-items: center;
  max-width: 1250px;
  width: 90%;
  margin: 0 auto;
  padding: 70px 0 ;
}

.intro-box h1 {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 100px;
  text-decoration: underline;
  text-underline-offset: 15px;
  color: #FFF;
}

.image__intro{
  max-height: 400px;
}

.intro-item-text{
  padding-right: 100px;
  align-items: left;
  text-align: left;
  color:#fff;
}

.intro-item-text h3{
  font-size: 40px;
  font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color:#2b39a5;
}

@media screen and (max-width: 960px) {
  .intro-box > h1 {
    font-size: 50px;
    /* margin-top: -150px; */
  }
}


.welcome-text{
  font-family:  'Montserrat', sans-serif;
}
.welcome-desc{
    font-family:  'Montserrat', sans-serif;
}
/*! Flickity v1.1.2
http://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled{position:relative}.flickity-enabled:focus{outline:0}.flickity-viewport{overflow:hidden;position:relative;height:100%}.flickity-slider{position:absolute;width:100%;height:100%}.flickity-enabled.is-draggable{-webkit-tap-highlight-color:transparent;tap-highlight-color:transparent;-webkit-user-select:none;-ms-user-select:none;user-select:none}.flickity-enabled.is-draggable .flickity-viewport{cursor:move;cursor:-webkit-grab;cursor:grab}.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down{cursor:-webkit-grabbing;cursor:grabbing}.flickity-prev-next-button{position:absolute;top:50%;width:44px;height:44px;border:none;border-radius:50%;background:#fff;background:hsla(0,0%,100%,.75);cursor:pointer;transform:translateY(-50%)}.flickity-prev-next-button:hover{background:#fff}.flickity-prev-next-button:focus{outline:0;box-shadow:0 0 0 5px #09F}.flickity-prev-next-button:active{filter:alpha(opacity=60);opacity:.6}.flickity-prev-next-button.previous{left:10px}.flickity-prev-next-button.next{right:10px}.flickity-rtl .flickity-prev-next-button.previous{left:auto;right:10px}.flickity-rtl .flickity-prev-next-button.next{right:auto;left:10px}.flickity-prev-next-button:disabled{filter:alpha(opacity=30);opacity:.3;cursor:auto}.flickity-prev-next-button svg{position:absolute;left:20%;top:20%;width:60%;height:60%}.flickity-prev-next-button .arrow{fill:#333}.flickity-prev-next-button.no-svg{color:#333;font-size:26px}.flickity-page-dots{position:absolute;width:100%;bottom:-25px;padding:0;margin:0;list-style:none;text-align:center;line-height:1}.flickity-rtl .flickity-page-dots{direction:rtl}.flickity-page-dots .dot{display:inline-block;width:10px;height:10px;margin:0 8px;background:#333;border-radius:50%;filter:alpha(opacity=25);opacity:.25;cursor:pointer}.flickity-page-dots .dot.is-selected{filter:alpha(opacity=100);opacity:1}
.footer-container {
  background-color: #242424;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex; 
  /* flex-direction: column;  */
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 20px;
  text-align: left;
  width: 400px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-decoration: none;
  margin-bottom: 16px;
  font-size: 25px;
}

.footer-link-items > h2 {
  color: #fff;
}


.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}



/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo:hover{
  text-decoration: none;
}

.gmaps-link:hover{
  text-decoration: none;
}

.socmed-link:hover{
  text-decoration: none;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
}


/* Sponsor  */


.sponsor-wrapper {
  display: flex;

}

.upper-sponsor{
  padding-bottom: 30px;
}
.sponsor-item{
  padding: 0px 25px;

}

.sponsor-item img{
  max-height: 80px;
}




.footer-sponsor{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  /* margin-bottom: 24px; */
  padding: 24px;
  color: #fff;

}

.sponsor-bg{
  background-color: #0d1d79;
}

.supported-bg{
  background-color: #256f4a;
}

.partner-bg{
  background-color: #4c256f;
}



.footer-sponsor-heading {
  margin-bottom: 24px;
  font-size: 40px;
}


.fas {
  color:#fff;
  padding-right: 10px;
}

.text{
  color:#fff;   
  font-size: 20px;
  padding-bottom: 10px;
}

.text > .fab{
  padding-right:10px;
}

a > .fab{
  padding-right:10px;
}
#sponsor-supported-logo{
  max-height:none;
  height: 250px;
}

@media screen and (max-width: 960px) {
  .footer-link-wrapper {
    flex-direction: w;

  }
  .footer-link-items{
    padding-bottom: 20px;
  }

  #sponsor-supported-logo{
    max-height:none;
    height: 150px;
  }
}


@media screen and (max-width: 768px) {
  .sponsor-item > img{
    height: 30px;
  }
  }

.carousel__container{
    padding-top: 80px;
    background-color: rgb(86, 73, 204);
    padding-bottom: 100px;
}

.carousel__container  .carousel.is-fullscreen  {

    
}



.carousel-cell {
    min-height: 100%;
}

.carousel.is-fullscreen .carousel-cell {
    height: 100%;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.carousel.is-fullscreen{
    margin-left:10%;
    position: fixed;
    z-index: 100;

}

.carousel.is-fullscreen .carousel-cell-image {
    height: 100%;
    max-width: 100%;
} 

.carousel-cell-image {
    display: block;
    margin-right: 5px;
}

  


.caro_item{
    width:450px;
    padding-right: 10px;
}

.carotitle__container {
    background-color: rgb(86, 73, 204);

}

.carotitle__container > h1 {
    color: #fff;
    font-size: 150px;
    padding: 30px 0px;
  }
 
.carousel{
    max-width: 1200px;
    margin: auto;
}

.fullscreen-btn{
    position: absolute;
    top: 5%;
    left: 90%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: rgb(0, 0, 0);
    color: white;

    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;

    cursor: pointer;

}

.fa-expand{
    font-size: 30px;
    text-align: center;
}


.modal_img_home{
    width:100%
}


@media screen and (max-width: 768px) {
    .carotitle__container > h1{
        font-size:60px;
    }
  }

.events-section {
  background-color: #083b27;
  padding: 7em 0;
}

.events-container {
  width: 85%;
  max-width: 65em;
  margin: 0 auto;
}

.split {
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  flex-wrap: wrap;
  justify-content: center;
}

.split > * {
  flex-basis: 30%;
  min-width: 15em;
}

.spacing > * + * {
  margin-top: 2rem;
  margin-top: var(--spacer, 2rem);
}


.btn:hover,
.btn:focus {
  transform: scale(1.1);
  opacity: .9;
}

.primary-title {
  font-size: 4rem;
  font-size: clamp(3rem, calc(5vw + 1rem), 4.5rem);
  line-height: 1;
  text-transform: uppercase;
}

.section-title {
  text-align: center;
  font-size: 100px;
  line-height: 1;
  color: #ffffff;
  margin-bottom: 5rem;
}

.hero {
  color: white;
  text-align: center;
  padding: 15em 0;
  background: #222;
}

@supports (background-blend-mode: multiply) {
  .hero {
    /* background: url(../img/), radial-gradient(#444, #111); */
    background-blend-mode: multiply;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.featured {
  background: #eee;
}

.featured__item {
  display: block;
  position: relative;
  transform: scale(0.85);
  transition: transform 250ms ease-in-out;
  text-decoration: none;
  color: #333;
  text-align: center;
  line-height: 1.2;
}

.featured__item:hover, .featured__item:focus {
  transform: scale(1);
}

.featured__item:hover .featured__details, .featured__item:focus .featured__details {
  opacity: 1;
  text-shadow: 0 0 2em white;
}

.featured__item::after {
  content: '';
  position: absolute;
  top: 10%;
  left: 10%;
  padding: 75% 75% 0 0;
  border-radius: 50%;
  background: #2193b0;
  z-index: -1;
}

.featured__details {
  opacity: 0;
  transition: opacity 250ms linear;
}

.featured__details span {
  display: block;
  font-weight: 700;
  font-size: 2.5rem;
}

.product {
  background: var(--clr-accent);
  padding: 3em;
  border-radius: 1em;
  margin-bottom: 5em;
  text-align: center;
  box-shadow: 20px 20px var(--clr-inner);
}

.product__title {
  font-size: 58px;
  text-transform: uppercase;
  line-height: 1;
  color: rgb(255, 255, 255);
  text-shadow: 0 0 0.2em rgba(0, 0, 0, 0.2);
}

.product__description{
  font-size : 22px;
  color: rgb(255, 255, 255);
  font-family:  Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


@media (min-width: 45em) {
  .product {
    text-align: justify;
    margin-bottom: 9em;
  }
  .product__title {
    margin: 0;
  }
}

.geoclip {
  --clr-accent: #095046;
  --clr-inner: #2fc598;
}

.gis-event{
  --clr-accent: #64104b;
  --clr-inner: #e01585;
}

.gmc-event {
  --clr-accent: #a1173c;
  --clr-inner: #faa700;
}

.virtuex-event {
  --clr-accent: #662e0d;
  --clr-inner: #3d2fff;
}

.webinar-event {
  --clr-accent: #2e3035;
  --clr-inner: #fa0000;
}




/* Images */
.pimage1{
    float: right;
    width: 50%;
    margin-top: -80px;
    margin-right: -50px;
}
.pimage2{
  float: left;
  width: 50%;
  margin-top:-75px;
  margin-left: -70px;
}

.pimage3{
  float: right;
  width: 50%;
  margin-top: -80px;
  margin-right: -50px;
}

.pimage4{
  float: left;
  width: 50%;
  margin-top:-80px;
  margin-left: -70px;
}

.pimage5{
  float: right;
  width: 48%;
  margin-top: -90px;
  margin-right: -70px;

}


.info__events, .link__events{
  color:#fff;
}

.padleft-extra{
  padding-left:480px;
}


.btn-events{
  margin-top: 20px;
}


#modal1{
  font-size: 40px;
}
#modal2{
  font-size: 40px;
}
#modal3{
  font-size: 40px;
}
#modal4{
  font-size: 40px;
}
#modal5{
  font-size: 40px;
}

.modal__list{
  margin-bottom: 20px;
  margin-left: 20px;
}

.marg-zero{
  margin-bottom: 0px;
}

.paddown-hey{
  padding-bottom: 200px;
}
.paddown-hey1{
  padding-bottom: 100px;
}

#title1{
  font-size: 125px;
}

.modal-image{
  max-width: 750px;
  padding-bottom: 20px;
}

.modal__container > p {
  text-align: justify;
}

@media screen and (max-width: 768px) {
 #title1{
   font-size: 70px;
 }
 .product__title{
   font-size: 50px;
   padding-bottom: 20px;
 }
 .gmctitle{
   font-size: 40px;
   padding-bottom: 10px;
 }
 .modal-image{
  max-width: 350px;
}

}


@media screen and (max-width: 960px) {
 #title1{
   font-size: 100px;
 }
 .product__title{
   font-size: 50px;
   padding-bottom: 20px;
 }
 .gmctitle{
   font-size: 40px;
   padding-bottom: 10px;
 }
 .modal-image{
  max-width: 450px;
}

}

  .events-hero-container {
    background: linear-gradient(
      rgba(0, 0, 0, 0.5), 
      rgba(0, 0, 0, 0.5)
    ),url('/images/guide.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .events-hero-container > h1 {
    color: #fff;
    font-size: 250px;
    margin-top: -100px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .events-hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
 
  
  @media screen and (max-width: 960px) {
    .events-hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .events-hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .events-hero-container > p {
      font-size: 30px;
    }
  
  }
  

  .about-hero-container {
    background: linear-gradient(
      rgba(0, 0, 0, 0.5), 
      rgba(0, 0, 0, 0.5)
    ),url('/images/bgs/bg2.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .about-hero-container > h1 {
    color: #fff;
    font-size: 200px;
    margin-top: -100px;
    font-family: 'Montserrat', sans-serif;
  }

  #sub-header-about{
    font-family: 'Montserrat', sans-serif;
  }
  
  .about-hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
 
  
  @media screen and (max-width: 960px) {
    .about-hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .about-hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
      margin-left : -200px;
    }
  
    .about-hero-container > p {
      font-size: 30px;
      margin-left : 30px;
    }
    #sub-header-about{
      margin-left : -340px;
    }

    #sub-about{
      margin-left : 150px;
    }
  
  }
  


.aboutus-box{
    padding: 4rem;
    background: #34081f;
    display:flex;
  }
  
  .aboutus-container{
    display:flex;
  }
  
  .aboutus-wrapper{
    align-items: center;
    max-width: 1250px;
    width: 90%;
    margin: 0 auto;
    padding: 70px 0 ;
  }

  .aboutus-img{
    height: 450px;
    width: 575px;
    padding-top: 80px;
  }
  
  .aboutus-item-text{
    padding-right: 100px;
    align-items: left;
    text-align: left;
    color:#fff;
  }
  
  .aboutus-item-text h3{
    font-size: 40px;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color:#2b39a5;
  }
  

  .aboutus-box > .text-box > h1 {
      font-family: 'Montserrat', sans-serif;;
    font-size: 100px;
    text-align: left;
    /* margin-top: -60px; */
    padding-left: 25px;
  }

  .aboutus-box > .text-box > h2 {
    font-family:  sans-serif;
  font-size: 20px;
  /* margin-top: -60px; */
  padding-left: 25px;
  color:#fff;
  text-align: justify;
}
  @media screen and (max-width: 960px) {
    .aboutus-box > h1 {
      font-size: 50px;
      /* margin-top: -150px; */
    }
    .aboutus-box{
        flex-direction: column;
    }
    .aboutus-img{
        height: 300px;
        /* width: 300px; */
    }
  }



  @media screen and (max-width: 768px) {
    .aboutus-img{
        height: 250px;
         width: 300px;
    }
    #aboutustitle{
        font-size: 50px;
        padding-top: 20px;
    }
    #aboutustext{
        font-size: 15px;

    }
}


@media screen and (max-width: 960px) {
  .aboutus-img{
      height: 300px;
       width: 450px;
       padding: 30px;
  }

}

.history-bg{
    background: #810947;
}

.history-img{
    height: 350px;
    width: 575px;
    padding-top: 0px;
  }
  
  .history-box{
      padding-right: 10px;
  }
  @media screen and (max-width: 768px) {
   .history-img{
       height: 200px;
        width: 300px;
   }
   #historytitle{
       font-size: 50px;
   }
   #historytext{
       font-size: 15px;
       padding-bottom: 20px;
       text-align: center;
   }
}

#historytext{
    text-align: justify;
}

@media screen and (max-width: 960px) {
    .history-img{
        height: 300px;
         width: 450px;
         padding: 30px;
    }

 }
.cards {
    padding: 4rem;
    background: #0a1239;
  }
  
  h1 {
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 100px;
    color: #fff;
  }
  
  .cards__container {
  
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  
  .cards__wrapper {
  
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    background: #271d68;
    display: flex;
    flex: 1 1;
    margin: 0 1rem;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    -webkit-animation-name: fade-img;
            animation-name: fade-img;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #000;
    background-color: #00ffa6;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #fff;
    font-size: 18px;
    line-height: 24px;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }
  @media screen and (max-width: 768px) {
    #signupcards_title{
      font-size: 50px;
    }
  }
  

  .sign-hero-container {
    background: linear-gradient(
      rgba(0, 0, 0, 0.5), 
      rgba(0, 0, 0, 0.5)
    ),url('/images/back-hero.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .sign-hero-container > h1 {
    color: #fff;
    font-size: 140px;
    margin-top: -100px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .sign-hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  

  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .sign-hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .sign-hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .sign-hero-container > p {
      font-size: 30px;
    }
  
  }
  
.guide-book {
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
	display: flex;
	max-width: 100%;
	margin: 20px;
	overflow: hidden;
	width: 700px;
}

.guide-book h6 {
	opacity: 0.6;
	margin: 0;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.guide-book h2 {
	letter-spacing: 1px;
	margin: 10px 0;
}

.guide-book-preview {
	background-color: #057269;
	color: #fff;
	padding: 30px;
	width: 220px;
}

.guide-book-preview a {
	color: #fff;
	display: inline-block;
	font-size: 12px;
	opacity: 0.6;
	margin-top: 30px;
	text-decoration: none;
}

.guide-book-info {
	padding: 30px;
	position: relative;
	width: 100%;
}


.gb-btn {
	background-color: #25885f;
	border: 0;
	border-radius: 50px;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
	color: #fff;
	font-size: 16px;
	padding: 12px 25px;
	position: absolute;
	bottom: 30px;
	right: 30px;
	letter-spacing: 1px;
}


.download-title{
	padding: 30px;
}

.download-container{
	background-color: rgb(18, 60, 85);
	display: flex;
	flex-flow: column;
	align-items: center;
	/* max-width: 1120px; */
	/* width: 90%; */
	margin: 0 auto;
	padding-bottom: 80px;
}


@media screen and (max-width: 768px) {
    .download-title > h1{
      font-size: 50px;
	}
	.booklet-container{
		width: 350px;
	}
	.gb-btn-container{
		padding-top: 50px;
	}
  }

  .guide-hero-container {
    background: linear-gradient(
      rgba(0, 0, 0, 0.5), 
      rgba(0, 0, 0, 0.5)
    ),url('/images/geomodel.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .guide-hero-container > h1 {
    color: #fff;
    font-size: 175px;
    margin-top: -100px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .guide-hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
 
  
  @media screen and (max-width: 960px) {
    .guide-hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .guide-hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .guide-hero-container > p {
      font-size: 30px;
    }
  
  }
  
