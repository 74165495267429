.footer-container {
  background-color: #242424;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex; 
  /* flex-direction: column;  */
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 20px;
  text-align: left;
  width: 400px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-decoration: none;
  margin-bottom: 16px;
  font-size: 25px;
}

.footer-link-items > h2 {
  color: #fff;
}


.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}



/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo:hover{
  text-decoration: none;
}

.gmaps-link:hover{
  text-decoration: none;
}

.socmed-link:hover{
  text-decoration: none;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
}


/* Sponsor  */


.sponsor-wrapper {
  display: flex;

}

.upper-sponsor{
  padding-bottom: 30px;
}
.sponsor-item{
  padding: 0px 25px;

}

.sponsor-item img{
  max-height: 80px;
}




.footer-sponsor{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  /* margin-bottom: 24px; */
  padding: 24px;
  color: #fff;

}

.sponsor-bg{
  background-color: #0d1d79;
}

.supported-bg{
  background-color: #256f4a;
}

.partner-bg{
  background-color: #4c256f;
}



.footer-sponsor-heading {
  margin-bottom: 24px;
  font-size: 40px;
}


.fas {
  color:#fff;
  padding-right: 10px;
}

.text{
  color:#fff;   
  font-size: 20px;
  padding-bottom: 10px;
}

.text > .fab{
  padding-right:10px;
}

a > .fab{
  padding-right:10px;
}
#sponsor-supported-logo{
  max-height:none;
  height: 250px;
}

@media screen and (max-width: 960px) {
  .footer-link-wrapper {
    flex-direction: w;

  }
  .footer-link-items{
    padding-bottom: 20px;
  }

  #sponsor-supported-logo{
    max-height:none;
    height: 150px;
  }
}


@media screen and (max-width: 768px) {
  .sponsor-item > img{
    height: 30px;
  }
  }