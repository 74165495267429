
.carousel__container{
    padding-top: 80px;
    background-color: rgb(86, 73, 204);
    padding-bottom: 100px;
}

.carousel__container  .carousel.is-fullscreen  {

    
}



.carousel-cell {
    min-height: 100%;
}

.carousel.is-fullscreen .carousel-cell {
    height: 100%;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.carousel.is-fullscreen{
    margin-left:10%;
    position: fixed;
    z-index: 100;

}

.carousel.is-fullscreen .carousel-cell-image {
    height: 100%;
    max-width: 100%;
} 

.carousel-cell-image {
    display: block;
    margin-right: 5px;
}

  


.caro_item{
    width:450px;
    padding-right: 10px;
}

.carotitle__container {
    background-color: rgb(86, 73, 204);

}

.carotitle__container > h1 {
    color: #fff;
    font-size: 150px;
    padding: 30px 0px;
  }
 
.carousel{
    max-width: 1200px;
    margin: auto;
}

.fullscreen-btn{
    position: absolute;
    top: 5%;
    left: 90%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: rgb(0, 0, 0);
    color: white;

    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;

    cursor: pointer;

}

.fa-expand{
    font-size: 30px;
    text-align: center;
}


.modal_img_home{
    width:100%
}


@media screen and (max-width: 768px) {
    .carotitle__container > h1{
        font-size:60px;
    }
  }