
.history-bg{
    background: #810947;
}

.history-img{
    height: 350px;
    width: 575px;
    padding-top: 0px;
  }
  
  .history-box{
      padding-right: 10px;
  }
  @media screen and (max-width: 768px) {
   .history-img{
       height: 200px;
        width: 300px;
   }
   #historytitle{
       font-size: 50px;
   }
   #historytext{
       font-size: 15px;
       padding-bottom: 20px;
       text-align: center;
   }
}

#historytext{
    text-align: justify;
}

@media screen and (max-width: 960px) {
    .history-img{
        height: 300px;
         width: 450px;
         padding: 30px;
    }

 }