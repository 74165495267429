@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');


.guide-book {
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
	display: flex;
	max-width: 100%;
	margin: 20px;
	overflow: hidden;
	width: 700px;
}

.guide-book h6 {
	opacity: 0.6;
	margin: 0;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.guide-book h2 {
	letter-spacing: 1px;
	margin: 10px 0;
}

.guide-book-preview {
	background-color: #057269;
	color: #fff;
	padding: 30px;
	width: 220px;
}

.guide-book-preview a {
	color: #fff;
	display: inline-block;
	font-size: 12px;
	opacity: 0.6;
	margin-top: 30px;
	text-decoration: none;
}

.guide-book-info {
	padding: 30px;
	position: relative;
	width: 100%;
}


.gb-btn {
	background-color: #25885f;
	border: 0;
	border-radius: 50px;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
	color: #fff;
	font-size: 16px;
	padding: 12px 25px;
	position: absolute;
	bottom: 30px;
	right: 30px;
	letter-spacing: 1px;
}


.download-title{
	padding: 30px;
}

.download-container{
	background-color: rgb(18, 60, 85);
	display: flex;
	flex-flow: column;
	align-items: center;
	/* max-width: 1120px; */
	/* width: 90%; */
	margin: 0 auto;
	padding-bottom: 80px;
}


@media screen and (max-width: 768px) {
    .download-title > h1{
      font-size: 50px;
	}
	.booklet-container{
		width: 350px;
	}
	.gb-btn-container{
		padding-top: 50px;
	}
  }