

.aboutus-box{
    padding: 4rem;
    background: #34081f;
    display:flex;
  }
  
  .aboutus-container{
    display:flex;
  }
  
  .aboutus-wrapper{
    align-items: center;
    max-width: 1250px;
    width: 90%;
    margin: 0 auto;
    padding: 70px 0 ;
  }

  .aboutus-img{
    height: 450px;
    width: 575px;
    padding-top: 80px;
  }
  
  .aboutus-item-text{
    padding-right: 100px;
    align-items: left;
    text-align: left;
    color:#fff;
  }
  
  .aboutus-item-text h3{
    font-size: 40px;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color:#2b39a5;
  }
  

  .aboutus-box > .text-box > h1 {
      font-family: 'Montserrat', sans-serif;;
    font-size: 100px;
    text-align: left;
    /* margin-top: -60px; */
    padding-left: 25px;
  }

  .aboutus-box > .text-box > h2 {
    font-family:  sans-serif;
  font-size: 20px;
  /* margin-top: -60px; */
  padding-left: 25px;
  color:#fff;
  text-align: justify;
}
  @media screen and (max-width: 960px) {
    .aboutus-box > h1 {
      font-size: 50px;
      /* margin-top: -150px; */
    }
    .aboutus-box{
        flex-direction: column;
    }
    .aboutus-img{
        height: 300px;
        /* width: 300px; */
    }
  }



  @media screen and (max-width: 768px) {
    .aboutus-img{
        height: 250px;
         width: 300px;
    }
    #aboutustitle{
        font-size: 50px;
        padding-top: 20px;
    }
    #aboutustext{
        font-size: 15px;

    }
}


@media screen and (max-width: 960px) {
  .aboutus-img{
      height: 300px;
       width: 450px;
       padding: 30px;
  }

}