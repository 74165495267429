
  .guide-hero-container {
    background: linear-gradient(
      rgba(0, 0, 0, 0.5), 
      rgba(0, 0, 0, 0.5)
    ),url('/images/geomodel.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .guide-hero-container > h1 {
    color: #fff;
    font-size: 175px;
    margin-top: -100px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .guide-hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
 
  
  @media screen and (max-width: 960px) {
    .guide-hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .guide-hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .guide-hero-container > p {
      font-size: 30px;
    }
  
  }
  