video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  background:  linear-gradient(
    rgba(110, 108, 108, 0.4), 
    rgba(0, 0, 0, 0.9)
  ), url('/images/bgs/bg1.jpg')  center center/cover;
  height: 120vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}


.welcome-text{

  font-family: 'Bebas Neue',cursive;
  padding: 30px;

}


.hero-container > h1 {
  color: #fff;
  font-size: 140px;
  margin-top: 20px;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  padding-left: 200px;
}

.hero-container > p {
  margin-top: -30px;
  padding-left: 230px;
  margin-bottom: 50px;  
  color: #fff;
  font-size: 32px;
  font-family: 'Montserrat','Sans Serif';
 text-align: left;
}

.hero-btns {
  margin-top: 32px;
  padding-left: 230px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }

  .hero-container > h1 {
    padding-left: 20px;
  }
  .hero-container > p {
    padding-left: 20px;
  }
  .hero-btns{
    padding-left: 20px;
  }
}
