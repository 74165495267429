
.btn-style {
  padding: 8px 20px;

  border: white;
  cursor: pointer;
}

.btn--primary {
  background-color: rgb(212, 212, 212);
  color: #242424;
  border: 1px solid  #fff;

}


.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #fff;
  transition: all 0.3s ease-out;

}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
}


