
.events-section {
  background-color: #083b27;
  padding: 7em 0;
}

.events-container {
  width: 85%;
  max-width: 65em;
  margin: 0 auto;
}

.split {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1em;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.split > * {
  -ms-flex-preferred-size: 30%;
      flex-basis: 30%;
  min-width: 15em;
}

.spacing > * + * {
  margin-top: var(--spacer, 2rem);
}


.btn:hover,
.btn:focus {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  opacity: .9;
}

.primary-title {
  font-size: 4rem;
  font-size: clamp(3rem, calc(5vw + 1rem), 4.5rem);
  line-height: 1;
  text-transform: uppercase;
}

.section-title {
  text-align: center;
  font-size: 100px;
  line-height: 1;
  color: #ffffff;
  margin-bottom: 5rem;
}

.hero {
  color: white;
  text-align: center;
  padding: 15em 0;
  background: #222;
}

@supports (background-blend-mode: multiply) {
  .hero {
    /* background: url(../img/), radial-gradient(#444, #111); */
    background-blend-mode: multiply;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.featured {
  background: #eee;
}

.featured__item {
  display: block;
  position: relative;
  -webkit-transform: scale(0.85);
          transform: scale(0.85);
  -webkit-transition: -webkit-transform 250ms ease-in-out;
  transition: -webkit-transform 250ms ease-in-out;
  transition: transform 250ms ease-in-out;
  transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
  text-decoration: none;
  color: #333;
  text-align: center;
  line-height: 1.2;
}

.featured__item:hover, .featured__item:focus {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.featured__item:hover .featured__details, .featured__item:focus .featured__details {
  opacity: 1;
  text-shadow: 0 0 2em white;
}

.featured__item::after {
  content: '';
  position: absolute;
  top: 10%;
  left: 10%;
  padding: 75% 75% 0 0;
  border-radius: 50%;
  background: #2193b0;
  z-index: -1;
}

.featured__details {
  opacity: 0;
  -webkit-transition: opacity 250ms linear;
  transition: opacity 250ms linear;
}

.featured__details span {
  display: block;
  font-weight: 700;
  font-size: 2.5rem;
}

.product {
  background: var(--clr-accent);
  padding: 3em;
  border-radius: 1em;
  margin-bottom: 5em;
  text-align: center;
  box-shadow: 20px 20px var(--clr-inner);
}

.product__title {
  font-size: 58px;
  text-transform: uppercase;
  line-height: 1;
  color: rgb(255, 255, 255);
  text-shadow: 0 0 0.2em rgba(0, 0, 0, 0.2);
}

.product__description{
  font-size : 22px;
  color: rgb(255, 255, 255);
  font-family:  Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


@media (min-width: 45em) {
  .product {
    text-align: justify;
    margin-bottom: 9em;
  }
  .product__title {
    margin: 0;
  }
}

.geoclip {
  --clr-accent: #095046;
  --clr-inner: #2fc598;
}

.gis-event{
  --clr-accent: #64104b;
  --clr-inner: #e01585;
}

.gmc-event {
  --clr-accent: #a1173c;
  --clr-inner: #faa700;
}

.virtuex-event {
  --clr-accent: #662e0d;
  --clr-inner: #3d2fff;
}

.webinar-event {
  --clr-accent: #2e3035;
  --clr-inner: #fa0000;
}




/* Images */
.pimage1{
    float: right;
    width: 50%;
    margin-top: -80px;
    margin-right: -50px;
}
.pimage2{
  float: left;
  width: 50%;
  margin-top:-75px;
  margin-left: -70px;
}

.pimage3{
  float: right;
  width: 50%;
  margin-top: -80px;
  margin-right: -50px;
}

.pimage4{
  float: left;
  width: 50%;
  margin-top:-80px;
  margin-left: -70px;
}

.pimage5{
  float: right;
  width: 48%;
  margin-top: -90px;
  margin-right: -70px;

}


.info__events, .link__events{
  color:#fff;
}

.padleft-extra{
  padding-left:480px;
}


.btn-events{
  margin-top: 20px;
}


#modal1{
  font-size: 40px;
}
#modal2{
  font-size: 40px;
}
#modal3{
  font-size: 40px;
}
#modal4{
  font-size: 40px;
}
#modal5{
  font-size: 40px;
}

.modal__list{
  margin-bottom: 20px;
  margin-left: 20px;
}

.marg-zero{
  margin-bottom: 0px;
}

.paddown-hey{
  padding-bottom: 200px;
}
.paddown-hey1{
  padding-bottom: 100px;
}

#title1{
  font-size: 125px;
}

.modal-image{
  max-width: 750px;
  padding-bottom: 20px;
}

.modal__container > p {
  text-align: justify;
}

@media screen and (max-width: 768px) {
 #title1{
   font-size: 70px;
 }
 .product__title{
   font-size: 50px;
   padding-bottom: 20px;
 }
 .gmctitle{
   font-size: 40px;
   padding-bottom: 10px;
 }
 .modal-image{
  max-width: 350px;
}

}


@media screen and (max-width: 960px) {
 #title1{
   font-size: 100px;
 }
 .product__title{
   font-size: 50px;
   padding-bottom: 20px;
 }
 .gmctitle{
   font-size: 40px;
   padding-bottom: 10px;
 }
 .modal-image{
  max-width: 450px;
}

}