
.intro-box{
  padding: 4rem;
  background: #25283D;
}

.intro-container{
  display:flex;
}

.intro-wrapper{
  align-items: center;
  max-width: 1250px;
  width: 90%;
  margin: 0 auto;
  padding: 70px 0 ;
}

.intro-box h1 {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 100px;
  text-decoration: underline;
  text-underline-offset: 15px;
  color: #FFF;
}

.image__intro{
  max-height: 400px;
}

.intro-item-text{
  padding-right: 100px;
  align-items: left;
  text-align: left;
  color:#fff;
}

.intro-item-text h3{
  font-size: 40px;
  font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color:#2b39a5;
}

@media screen and (max-width: 960px) {
  .intro-box > h1 {
    font-size: 50px;
    /* margin-top: -150px; */
  }
}


.welcome-text{
  font-family:  'Montserrat', sans-serif;
}
.welcome-desc{
    font-family:  'Montserrat', sans-serif;
}