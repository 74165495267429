
  .about-hero-container {
    background: linear-gradient(
      rgba(0, 0, 0, 0.5), 
      rgba(0, 0, 0, 0.5)
    ),url('/images/bgs/bg2.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .about-hero-container > h1 {
    color: #fff;
    font-size: 200px;
    margin-top: -100px;
    font-family: 'Montserrat', sans-serif;
  }

  #sub-header-about{
    font-family: 'Montserrat', sans-serif;
  }
  
  .about-hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
 
  
  @media screen and (max-width: 960px) {
    .about-hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .about-hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
      margin-left : -200px;
    }
  
    .about-hero-container > p {
      font-size: 30px;
      margin-left : 30px;
    }
    #sub-header-about{
      margin-left : -340px;
    }

    #sub-about{
      margin-left : 150px;
    }
  
  }
  